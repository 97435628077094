import NotFoundBreadCrumb from './NotFoundBreadCrumb'

const NotFoundTexts = () => {
  return (
    <div>
      <div className="hidden sm:flex">
        <NotFoundBreadCrumb />
      </div>

      <h2 className="text-2xl sm:text-[40px] mt-8 sm:mt-12  font-inter font-medium sm:font-normal leading-8 sm:leading-[48px] text-restructure-primary">
        Ops, parece que você se perdeu na trilha...
      </h2>
      <h5 className="font-inter text-base sm:text-xl font-semibold sm:font-medium  my-4 text-restructure-primary">
        Mas fique tranquilo, te ajudamos a voltar!
      </h5>
      <p className="font-inter text-base font-normal sm:text-lg text-restructure-tertiary">
        Retome seu caminho ou navegue pelas nossas categorias e produtos
      </p>
    </div>
  )
}

export default NotFoundTexts
