import notfoundDesktop from '../../images/notfoundDesktop.png'
import notfoundMobile from '../../images/notfoundMobile.png'
import NotFoundCategories from './NotFoundCategories'
import NotFoundTexts from './NotFoundTexts'
import { RecommendationProvider } from '../restructure/product/contexts/recommendations-context'
import NotFoundBreadCrumb from './NotFoundBreadCrumb'
import GenericShelfv2 from '../restructure/product/shelves/GenericShelfV2'

const NotFound = () => {
  return (
    <div className="max-w-[1280px] mx-auto">
      <div className="flex sm:hidden">
        <NotFoundBreadCrumb />
      </div>
      <div className="flex flex-col-reverse sm:flex-row sm:justify-between">
        <div className="max-w-[518px] sm:w-1/2 px-4">
          <NotFoundTexts />
          <NotFoundCategories />
        </div>

        <div className="sm:w-1/2">
          <picture>
            <source srcSet={notfoundDesktop} media="(min-width: 769px)" />

            <img
              className="w-full h-full object-cover"
              src={notfoundMobile}
              alt="404 não encontrado"
            />
          </picture>
        </div>
      </div>

      <div className="mt-24 mb-[120px] sm:mb-20">
        <RecommendationProvider pageName="emptysearch">
          <GenericShelfv2 enableSession position="topo" />
          <GenericShelfv2 enableSession position="meio" />
          <GenericShelfv2 enableSession position="baixo" />
        </RecommendationProvider>
      </div>
    </div>
  )
}

export default NotFound
