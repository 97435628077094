export function HomeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className="w-4 h-4 min-w-[16px]"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66675 13.7656C2.66675 14.1338 2.96522 14.4323 3.33341 14.4323H12.6667C13.0349 14.4323 13.3334 14.1338 13.3334 13.7656V7.76561H15.3334L8.44875 1.50695C8.1944 1.27552 7.80576 1.27552 7.55141 1.50695L0.666748 7.76561H2.66675V13.7656ZM12.0001 6.53696V13.099H4.00008V6.53696L8.00008 2.90096L12.0001 6.53696Z"
        fill="#687787"
      />
    </svg>
  )
}
