import { Link } from 'gatsby'

import { HomeIcon } from '../Icons/HomeIcon'

const NotFoundBreadCrumb = () => {
  return (
    <nav className="breadcrumb_pdp flex ppp:flex-col pp:flex-row mt-4 mb-7 sm:mb-0 px-4 sm:px-0">
      <div className="flex flex-wrap items-center">
        <Link className="flex ppp:text-xs pp:text-sm items-center w-fit" to="/">
          <HomeIcon />
          <span className="font-inter text-sm text-darkGray ml-1">
            Página inicial
          </span>
        </Link>
        <span className="font-inter text-sm text-darkGray mx-1">/</span>
        <span className="font-inter text-xs font-normal text-[#101010]">
          Busca
        </span>
      </div>
    </nav>
  )
}

export default NotFoundBreadCrumb
